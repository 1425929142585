import api from '@/plugins/http'

export default {
  namespaced: true,
  state: {
    account: {},
    favorites: [],
    cart: {},
    orders: [],
    histories: [],
    order: {},
    newebpay: {},
    distance: {},
    addresses: [],
    currentAddress: '',
    cdOrderStatus: [],
    cdOrders: [],
    cdOrder: {
      orderDetail: []
    },
  },
  mutations: {
    UPDATE_ACCOUNT (state, data) {
      state.account = data
    },
    UPDATE_FAVORITES (state, data) {
      state.favorites = data
    },
    UPDATE_CART (state, data) {
      state.cart = data
    },
    UPDATE_ORDERS (state, data) {
      state.orders = data
    },
    UPDATE_HISTORIES (state, data) {
      state.histories = data
    },
    UPDATE_ORDER (state, data) {
      state.order = data
    },
    UPDATE_NEWEBPAY (state, data) {
      state.newebpay = data
    },
    UPDATE_DISTANCE (state, data) {
      state.distance = data
    },
    UPDATE_ADDRESSES (state, data) {
      state.addresses = data.addresses
    },
    UPDATE_CURRENT_ADDRESS (state, data) {
      state.currentAddress = data
    },
    UPDATE_CDORDERS_STATUS (state, data) {
      state.cdOrderStatus = data
    },
    UPDATE_CDORDERS (state, data) {
      state.cdOrders = data
    },
    UPDATE_CDORDER (state, data) {
      state.cdOrder = data
    },
  },
  actions: {
    async getAccount ({ commit }) {
      try {
        const response = await api.get(`/account`)
        commit('UPDATE_ACCOUNT', response.data())
        return response.successful()
      } catch (e) {
        return false
      }
    },
    async updateAccount ({ commit }, { ...columns }) {
      try {
        const response = await api.patch(`/account`, columns)
        commit('UPDATE_ACCOUNT', response.data())
        return response.successful()
      } catch (e) {
        return false
      }
    },
    async getFavorites ({ commit }) {
      try {
        const response = await api.get(`/favorites`)
        commit('UPDATE_FAVORITES', response.data())
        return response.successful()
      } catch (e) {
        return false
      }
    },
    async addFavorite ({ commit }, id) {
      try {
        const response = await api.post(`/favorite/${id}`)
        commit('UPDATE_FAVORITES', response.data())
        return response.successful()
      } catch (e) {
        return false
      }
    },
    async deleteFavorite ({ commit }, id) {
      try {
        const response = await api.delete(`/favorite/${id}`)
        commit('UPDATE_FAVORITES', response.data())
        return response.successful()
      } catch (e) {
        return false
      }
    },
    async getCart ({ commit }) {
      try {
        const response = await api.get(`/cart`)
        commit('UPDATE_CART', response.data())
        return response.successful()
      } catch (e) {
        return false
      }
    },
    async updateCart ({ commit }, {product, options, description, group_id, order_src, order_src_params}) {
      try {
        const response = await api.post(`/cart`, { product: product, options: options, description: description, group_id: group_id, order_src: order_src, order_src_params: order_src_params })
        commit('UPDATE_CART', response.data())
        return response.successful()
      } catch (e) {
        return false
      }
    },
    async clearCart ({ commit }) {
      try {
        const response = await api.delete(`/cart/destroy`)
        commit('UPDATE_CART', response.data())
        return response.successful()
      } catch (e) {
        return false
      }
    },
    async checkout ({ commit }, order_option) {
      try {
        const response = await api.post(`/checkout`, { order_option: order_option }).catch(error => {
          window.console.log(error);
          return false
        })
        commit('UPDATE_ORDER', response.data())
        return response.successful()
      } catch (e) {
        return false
      }
    },
    async updateCartItem ({ commit }, cart_item) {
      try {
        const response = await api.post(`/cart_item`, { cart_item: cart_item })
        commit('UPDATE_CART', response.data())
        return response.successful()
      } catch (e) {
        return false
      }
    },
    async getOrders ({ commit }, limit) {
      try {
        const response = await api.get(`/order?limit=` + limit)
        commit('UPDATE_ORDERS', response.data())
        return response.successful()
      } catch (e) {
        return false
      }
    },
    async getHistories ({ commit }, limit) {
      try {
        const response = await api.get(`/history?limit=` + limit)
        commit('UPDATE_HISTORIES', response.data())
        return response.successful()
      } catch (e) {
        return false
      }
    },
    async getOrder ({ commit }, id) {
      try {
        const response = await api.get(`/order/${id}`)
        commit('UPDATE_ORDER', response.data())
        return response.successful()
      } catch (e) {
        return false
      }
    },
    async getNewebpay ({ commit }, id) {
      try {
        const response = await api.get(`/newebpay/${id}`)
        commit('UPDATE_NEWEBPAY', response.data())
        return response.successful()
      } catch (e) {
        return false
      }
    },
    async getDistance ({ commit }, {id, address, amount, quantity}) {
      try {
        const response = await api.post(`/store/${id}/get_distance`, { address: address, amount: amount, quantity: quantity })
        commit('UPDATE_DISTANCE', response.data())
        return response.successful()
      } catch (e) {
        return false
      }
    },
    async autoComplete ({ commit }, address) {
      try {
        const response = await api.post(`autocomplete`, { address: address })
        commit('UPDATE_ADDRESSES', response.data())
        return response.successful()
      } catch (e) {
        return false
      }
    },
    async getCurrentAddress ({ commit }, pos) {
      try {
        const response = await api.post(`get_current_address`, { lat: pos.lat, lng: pos.lng })
        commit('UPDATE_CURRENT_ADDRESS', response.data())
        return response.successful()
      } catch (e) {
        return false
      }
    },
    async getCdOrderStatus ({ commit }) {
      try {
        const response = await api.get(`/cd_orders/status`)
        commit('UPDATE_CDORDERS_STATUS', response.data())
        return response.successful()
      } catch (e) {
        return false
      }
    },
    async getCdOrders ({ commit }, id) {
      try {
        commit('UPDATE_CDORDERS', [])
        const response = await api.get(`/cd_orders/${id}`)
        commit('UPDATE_CDORDERS', response.data())
        return response.successful()
      } catch (e) {
        return false
      }
    },
    async getCdOrder ({ commit }, {order_id, cd_order_id}) {
      try {
        commit('UPDATE_CDORDER', {})
        const response = await api.get(`/cd_orders/${order_id}/${cd_order_id}`)
        commit('UPDATE_CDORDER', response.data())
        return response.successful()
      } catch (e) {
        return false
      }
    },
  }
}
